import React from "react"
import {Container, Row,Col} from "react-bootstrap"
import {Link,TransitionLink} from 'gatsby-plugin-transition-link'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Fade from 'react-reveal/Fade';

import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

const Project = ({data}) =>{ 
    
    const backgroundFluidImageStack = [
        data.strapiAndproject.pheroimg.childImageSharp.fluid,
        `linear-gradient(0deg, rgba(0, 0, 0, 0.616), rgba(0, 0, 0, 0.2))`
      ].reverse()
    const backgroundFluidImageStack2 = [
        data.strapiAndproject.bgimage.childImageSharp.fluid,
        `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.75))`
      ].reverse()
    return(

  <Layout>
    <SEO title={data.strapiAndproject.pname} />
    <Container fluid>
        <BackgroundImage tag="div" className="row proy align-items-center align-content-center" fluid={backgroundFluidImageStack}>
            <Fade>
            <Col md={{span:6,offset:4}} xs={12} className="proy-title">

                <h1 className="mb-3 ">{data.strapiAndproject.pname}</h1>
                <p className="mt-4">{data.strapiAndproject.shortdesc}</p>
            </Col>
            </Fade>
        </BackgroundImage>
    </Container>
<BackgroundImage tag="div" className="container-fluid proy-2" fluid={backgroundFluidImageStack2}>
    <Row>
        <Col md={{span:7,offset:2}} className="mt-5 mb-5 ">
          <Fade bottom>

          <ReactMarkdown source={data.strapiAndproject.phrase} />
          </Fade>
        </Col>
    </Row>
    <Row className="align-items-center">
        <Col md={{span:5,offset:1}}>
        <Fade bottom>

            <ReactMarkdown source={data.strapiAndproject.description} />
        </Fade>
        </Col>
        <Col md={6}>
        <Fade>

 <Img fluid={data.strapiAndproject.ftimg.childImageSharp.fluid} alt="" className="img-fluid"/>
        </Fade>
        </Col>
    </Row>
</BackgroundImage>

<Container fluid>

<div className="pgrid">
<p>{data.strapiAndproject.gallery.url}</p>

{data.strapiAndproject.gallery.map(gallery=>(
    
<Fade >

    <img className="item" src={gallery.url}/>
</Fade>
    
))}

</div>

</Container>

   
  </Layout>
);}

export default Project

export const query= graphql`
query AndprojTemplate( $id:String!){
    strapiAndproject(id: {eq:$id}) {
        pname
        permalink
        shortdesc
        pheroimg{
            childImageSharp {
                fluid(quality:90,maxWidth:1200){
                    ...GatsbyImageSharpFluid
                }
            }
        }
        phrase
        description
        bgimage{
            childImageSharp {
                fluid(quality:90,maxWidth:2560){
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ftimg{
            childImageSharp {
                fluid(quality:90,maxWidth:2560){
                    ...GatsbyImageSharpFluid
                }
            }
        }
        gallery{
            url
       
        }

    }
}
`
